
























import ODataTable from '@/components/ODataTable.vue'
import IODataTableHeader from '@/models/IODataTableHeader'
import Vue from 'vue'

export default Vue.extend({
  components: {
    ODataTable
  },
  computed: {
    headers(): IODataTableHeader[] {
      return [
        {
          text: 'Name',
          value: 'processName',
          searchable: true
        },
        {
          text: 'Start Date',
          value: 'startTime',
          format: 'datetime'
        },
        {
          text: 'End Date',
          value: 'endTime',
          format: 'datetime'
        },
        {
          text: 'Processed',
          value: 'processed',
          format: 'number'
        },
        {
          text: 'New',
          value: 'inserted',
          format: 'number'
        },
        {
          text: 'Updated',
          value: 'updated',
          format: 'number'
        },
        {
          text: 'Errored',
          value: 'errorRecords',
          format: 'number'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ]
    }
  },
  methods: {
    getStatusColor(value: string) {
      if (value === 'Complete') {
        return 'success'
      }

      return 'error'
    },
    onRowClick(e) {
      this.$router.push({
        name: 'Import',
        params: { id: e.id },
        query: { processId: e.processId, filter: 'Processed' }
      })
    }
  }
})
